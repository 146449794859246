@import url(https://fonts.googleapis.com/css?family=Bangers);
.textField{
  display: flex;
  justify-content: center;
  align-items: center;

}
body{
  background-image: url("../img/loginPageBackground.webp");
  overflow-x: hidden;
}
.submitButton{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.loginBox{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  min-height: 500px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-image: url("../img/loginFormBackground.png");
  background-size: cover;
  background-position: center; // Add this line
  border: 10px solid #222;
  border-radius:95% 4% 97% 5%/4% 94% 3% 95%;
  position: relative;
}
.pageContainer{
  padding-top: 1vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 96vw;
  margin-left: auto;
  margin-right: auto;
  background-color: #faf9f9;
}
.pageHeader{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.weekInfo{
  font-weight: bold;
  grid-column: 2;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tools{
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1vw;
}
.pageContent{
  display: flex;
  justify-content: center;
}
.Calendar{
  padding: 10px;
  display: grid;
  grid-template-columns: 6.5vw repeat(7, 12.5vw);
  justify-content: flex-start;
  align-items: flex-start;
  width: 95vw;
}
.dayName{
  overflow: hidden;
  font-size: 2vh;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 12.5wv;
}
.hours{
  grid-column: 1;
  display:grid;
  grid-template-rows: repeat(840, 2px);
  justify-content: center;
  border-right: 1px dashed;
}
.hour{
  font-weight: bold;
  grid-column: 1;
  width: 7vw;
  text-align: center;
  overflow: hidden;
  grid-row: span 30;
  border-top: 1px solid ;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hour:nth-child(2n) {
  border-top: 0.5px solid #b5b5b5;
}
.day{
  position: relative;
  display:grid;
  grid-template-rows: repeat(840, 2px);
  border-right: 1px dashed;
}
.day:nth-child(2n) {
  background-color: #f0f0f0;
}
.monday{
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: repeat(840, 2px);
  z-index: 1;
  width:100%;
}
.tuesday{
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: repeat(840, 2px);
  z-index: 1;
  width:100%;
}
.wednesday{
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: repeat(840, 2px);
  width:100%;
  z-index: 1;
}
.thursday{
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: repeat(840, 2px);
  z-index: 1;
  width:100%;
}
.friday{
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: repeat(840, 2px);
  z-index: 1;
  width:100%;
}
.saturday{
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: repeat(840, 2px);
  z-index: 1;
  width:100%;
}
.sunday{
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: repeat(840, 2px);
  z-index: 1;
  width:100%;
}
.visit{
  white-space: pre-wrap;  /* Prevent text from wrapping */
  overflow: hidden;  /* Hide text that exceeds the container width */
  text-overflow: ellipsis;
  justify-content: center;
  border-left: 8px solid;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}
.visitButton{
  padding: 10px;
  white-space: pre-wrap;  /* Prevent text from wrapping */
  overflow: clip;
  text-overflow: ellipsis;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: background-color 0.5s ease;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.event {
  position: absolute; /* Allow overlap by using absolute positioning */
  width: 96%;        /* Each event takes 100% of the available width */
  left: 2%;            /* Align the event to the left */
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;

  transition: transform 0.2s ease, z-index 0.2s ease; /* Smooth hover effect */
  z-index: 1;        /* Default stacking order */
}

.event:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  z-index: 10;            /* Bring this event to the front */
}

.visitButton div{
  text-align: left;
  width:100%;
  margin: 0.5lvh;
}
.formVisit{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.formVisitComponent{
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-grow: 1;
  width: 70%;
}
.formVisitComponent > * {
  flex: 1;
}
.gridBackground{
  grid-row: span 1;
}
.gridBackground:nth-child(60n + 1) {
  border-top: 1px solid ;
}
.gridBackground:nth-child(60n + 31) {
  border-top: 1px solid #b5b5b5 ;
}
.toolsAddVisit{
  display:flex;
}
.registerBox{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  min-height: 700px;
  border-radius: 10px;
  max-width: 700px;
  padding-top: 50px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-image: url("../img/loginFormBackground.png");
  background-size: cover;
  background-position: center; // Add this line
  border: 10px solid #222;
  border-radius:95% 4% 97% 5%/4% 94% 3% 95%;
  position: relative;
}
.dashBoardContainer {
  display: flex;
  justify-content: space-between;
}
.dashBoardBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.leftDashboardBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25vw;
  padding: 20px;
  min-width: 20vw;
}
.middleDashboardBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  margin-right: 20px;
  min-width: 45vw;
}
.rightDashboardBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25vw;
  padding: 20px;
  min-width: 20vw;
}
.dashboardProfileInfo{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  //background-color: #f0f0f0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.optionInProfile{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  height: 0;
  padding-top: 30%;  // This makes the height equal to the width
  margin:2px;
  background-color: #EEEEEE;
  border-radius: 5px;
  transition: background-color 0.4s ease, transform 0.3s ease;
  transform: scale(1);
  font-size: 0.8vw;
  font-weight: bold;
  border: 2px solid #C7C8CC;
}
.optionInProfile > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;  // Add padding if needed
  box-sizing: border-box;  // Make sure padding is included in width and height
}
.optionInProfile:hover{
  background-color: #DDDDDD;
  z-index: 10;
  cursor: pointer;
  transform: scale(1.1);
}
.optionsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.dashBoardCalendar{
  display: grid;
  overflow-x: auto;
  overflow-y: auto;
  grid-template-columns: 1.9vw 200%;
  width: 100%;
  height: 80vh;
}
.dashBoardCalendarInDayView{
  display: grid;
  overflow-x: auto;
  overflow-y: auto;
  grid-template-columns: 1.9vw 100vw;
  width: 100%;
  height: 80vh;
}
.columnIndex{
  grid-column: 2;
  display: grid;
  grid-template-columns: repeat(8, 12.5%);
}
.columnIndexInDayView{
  grid-column: 2;
  display: grid;
  grid-template-columns: repeat(8, 12.5vw);
}
.columnName{

  font-weight: bold;
  display: flex;
  border-right: 1px dashed;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #faf9f9;
  width: 100%;

}
.dashboardGridBackground{
  grid-row: span 1;
}
.dashboardGridBackground:nth-child(60n + 1):not(:first-child) {
  border-top: 1px solid ;
}
.dashboardGridBackground:nth-child(60n + 31) {
  border-top: 1px solid #b5b5b5 ;
}
.workerColumn{
  display: grid;
  grid-template-rows: repeat(840, 2px);
  border-right: 1px dashed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width:100%;
}

.dashboardCalendarHours{
  position: sticky;
  border-right: 2px solid;
  padding: 0;
  left: 0;
  background-color: #faf9f9;
  font-size: 0.7vw;
  width: 100%;
  grid-column: 1;
  z-index: 12;
  display:grid;
  grid-template-rows: repeat(840, 2px);
  justify-content: center;
}
.dashBoardHour{
  font-weight: bold;
  width: 101%;
  background-color: #faf9f9;
  grid-row: span 60;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashBoardHour:nth-child(n):not(:first-child){
  border-top: 1px solid ;
}
.dashboardCalendarGrid{
  display: grid;
  //background-color: #f0f0f0;
  flex-grow: 1;
  grid-column: 2;
  grid-template-rows: repeat(840, 2px);
  grid-template-columns: repeat(8, 12.5%);
  justify-content: flex-start;
  align-items: flex-start;
}
.dashboardCalendarGridInDayView{
  display: grid;
  position: relative;
  //background-color: #f0f0f0;
  flex-grow: 1;
  grid-column: 2;
  grid-template-rows: repeat(840, 2px);
  grid-template-columns: repeat(8, 12.5vw);
  justify-content: flex-start;
  align-items: flex-start;
}
.dashboardWorkerColumn{
  display: grid;
  grid-template-rows: repeat(840, 2px);
  position: relative;
}
.red-line{
  position: absolute;
  width: 100%;
  height: 2px; /* or however thick you want the line to be */
  background-color: #C40C0C;
  z-index: 10;
}
.workerAnalysisBox{
  height: 12vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoInAnalysis
{
  align-items: center;
  justify-content: center;
  text-align: center;
}
.infoInAnalysisBox{
  border: 1px solid black;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  padding-top: 45px;
  padding-right: 10px;
  padding-bottom: 45px;
  background-color: #f5f3f4;
  display: grid;
  grid-template-columns: 11vw 8vw 8vw 8vw 10vw 5vw;
  font-size: 0.8vw;
}
.selectWorkerCalendar{
  margin-left: 20px;
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid #b5b5b5;
  display: flex;
  align-items: center;
  height: 100%;
}


@media (max-width: 768px) {
  .optionsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .dashBoardCalendar{
    display: grid;
    overflow-x: auto;
    overflow-y: auto;
    grid-template-columns: 7vw 370%;
    width: 100%;
    height: 80vh;
  }
  .dashBoardContainer {
    overflow: auto;
    display: flex;
    justify-content: space-between;
  }
  .leftDashboardBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    padding: 20px;
    min-width: 75vw;
  }
  .middleDashboardBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    margin-right: 20px;
    min-width: 90vw;
  }
  .rightDashboardBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    padding: 20px;
    min-width: 85vw;
  }
  .columnName{
    font-weight: bold;
    display: flex;
    font-size: 12px;
    border-right: 1px dashed;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #faf9f9;
    width: 100%;

  }
  .dashboardCalendarHours{
    position: sticky;
    border-right: 1px solid;
    padding: 0;
    left: 0;
    background-color: #faf9f9;
    font-size: 10px;
    width: 100%;
    grid-column: 1;
    z-index: 12;
    display:grid;
    grid-template-rows: repeat(840, 2px);
    justify-content: center;
  }


  body {
    touch-action: manipulation; /* Prevents double-tap to zoom */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  html, body {
    overflow-x: hidden;
  }
  .pageContainer{
    padding-top: 1vh;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 96vw;
    margin-left: auto;
    margin-right: auto;
    background-color: #faf9f9;
  }
  .tools{
    grid-column: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1vw;
  }
  .pageHeader{
    overflow-x: auto;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 2.4fr 0.1fr 0.5fr;
    justify-content: center;
    align-items: center;
  }
  .pageContent{
    overflow: auto;
    width: 100%; // Ensure the content takes the full width of the parent
    height: 100%;
  }
  .Calendar{
    padding: 10px;
    display: grid;
    grid-template-columns: 9.5vw repeat(7, 45vw);
    justify-content: flex-start;
    align-items: flex-start;
    width: 95vw;
  }
  .hour{
    font-weight: bold;
    font-size: 10px;
    grid-column: 1;
    width: 10vw;
    text-align: center;
    overflow: hidden;
    grid-row: span 30;
    border-top: 1px solid ;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .weekInfo {
    font-weight: bold;
    grid-column: 2;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap; // Prevent text from wrapping
  }
  .selectWorkerCalendar{
    font-weight: bold;
    background-color: #f0f0f0;
    padding: 3px;
    font-size: 10px;
    border-radius: 4px;
    border: 1px solid #b5b5b5;
    display: flex;
    overflow: clip;
    align-items: center;
    height: auto;
  }
  .formVisitComponent{
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    width: 92%;
  }

  .loginBox{
    min-height: 400px;
  }
  .registerBox{
    min-height: 400px;
  }
  .dayName{
    overflow: hidden;
    font-size: 10px;
    text-align: center;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 12.5wv;
  }
}

